<template>
  <div class="login-container">
    <div class="row welcome">
      欢迎登录
    </div>
    <div class="row"><span class="iconfont icon-username"></span><input v-model="loginForm.username" type="text" class="input-username" placeholder="请输入用户名或手机号">
    </div>
    <div class="row"><span class="iconfont icon-password"></span><input v-model="loginForm.password" type="password" class="input-password" placeholder="请输入密码"></div>
    <div class="row"><input type="button" class="button-login" @click="handleLogin" value="登    录"></div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        phone: '',
        code: ''
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      showCodeText: '获取验证码',
      sendCodeDisabled: false
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    handleLogin() {
      this.loading = true
      this.$store.dispatch('user/login', this.loginForm).then(() => {
        this.$router.push({path: '/index'})
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.login-container {
  background: url(../../assets/login_bg.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  background-color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  padding:0 5%;
  padding-top: 20%;

}
.row{
  line-height: 1.6rem;
  margin: 0.2rem auto;
  width: 80%;
  overflow: hidden;
}
.row.welcome{
  color: #fff;
  text-align: center;
  font-size: 0.7rem;
  height: 2.8rem;
}
.row input{
  outline: 0;
  height: 1.3rem;
  line-height: 1.3rem;
  width: 100%;
  font-size: 0.5rem;
  padding-left: 1.2rem;
  border-radius: 0.2rem;
  box-sizing: border-box;
  background: rgba(255,255,255,.2);
  border: 1px solid rgba(255,255,255,.5);
  color: #fff;
}
.row .iconfont{
  height: 1.6rem;
  width: 1.3rem;
  position: absolute;
  display: inline-block;
  font-family: 'iconfont';
  font-size: 0.6rem;
  line-height: 1.6rem;
  text-align: center;
  color: white;
}
.row input[type=button]{
  padding-left: 0rem;
  background: #3385ff;
  color: #fff;
  border: none;
  border-radius: 0.8rem;
}
.row:nth-child(3) {
  height: 2.1rem;
}
::-webkit-input-placeholder{
  font-size: 0.38rem;
  color: #999;
}
</style>
